var isNewPasswordValid = false
var isConfirmationPasswordValid = false
var clickFieldConfirmationPass = false
$("#forgot-new-password").focusout(function() {
  checkValidButton()
})

$("#forgot-confirmation-new-password").focusout(function() {
  clickFieldConfirmationPass = true
  checkValidButton()
})

function checkValidButton() {
    if($('#forgot-new-password').val().length < 6) {
     isPasswordValid = false
     $('#forgot-new-password-message').html('Kata sandi terlalu pendek')
  } else if ($('#forgot-new-password').val().length > 25) {
     isPasswordValid = false
     $('#forgot-new-password-message').html('Kata sandi terlalu panjang')
  } else {
      isPasswordValid = true
      $('#forgot-new-password-message').html('')
  }

  if (isPasswordValid) {
      if(clickFieldConfirmationPass) {
          if($('#forgot-confirmation-new-password').val() != $('#forgot-new-password').val()) {
             isConfirmationPasswordValid = false
             $('#forgot-confirmation-password-message').html('Konfirmasi kata sandi tidak sama dengan kata sandi baru')
          } else {
              isConfirmationPasswordValid = true
              $('#forgot-confirmation-password-message').html('')
          }
      }
  }

  if(isPasswordValid && isConfirmationPasswordValid) {
     $('#reset-pass-button').removeClass('inactive').addClass('active')
  } else {
     $('#reset-pass-button').addClass('inactive').removeClass('active')
  }
}
$('#forgot-new-password, #forgot-confirmation-new-password').change(function(event){
   checkValidButton()
}).keyup(function(event){
   checkValidButton()
})

$(document).on('click', '#reset-pass #reset-pass-button', function(event) {
    $('#reset-pass button').prop('disabled', true);
    var value = {};
    value.password = $('#reset-pass input[name=password]').val();
    value.verification_code_password = $('#reset-pass').attr('data-id');
    initialApiRestrict('/forgot_password/reset', value, 'post').done(function(json) {
        if (json.status == "600") {
            $('#reset-pass').parent().addClass('collapse');
            $('#reset-pass-message').html('Kata sandi berhasil dirubah').fadeIn('slow', function(){
              setTimeout(function(){
                $('#reset-pass-message').fadeOut(5000);
                window.location = '/login';
              }, 1000);
            })
        }else{
            $('#reset-pass-message').html(json.error).fadeIn('slow', function(){
                setTimeout(function(){ $('#reset-pass-message').fadeOut(5000) }, 1000);
            })
        }
    }).always(function() {
        $('#reset-pass button').removeAttr('disabled');
    });
});

$('#overlay-reset-pass').click(function(event) {
    if (event.target === this) {
        $('#reset-pass').parent().addClass('collapse');
    }
});
$('#reset-pass .close-modal').click(function(event) {
    $('#reset-pass').parent().addClass('collapse');
});
